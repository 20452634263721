#navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 1;
  font-family: "Signika Negative";
  background-color: rgba(245, 241, 241, 0.032);
  backdrop-filter: blur(10px);
  width: 100vw;
  height: 50px;
  border-bottom: 1px rgba(57, 56, 56, 0.438) solid;
}
#navbar-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 2rem;
}
#name-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}
#name {
  font-weight: 800;
  letter-spacing: 3px;
  font-size: 15px;
  margin-left: 2rem;
  cursor: pointer;
  text-shadow: none;
}
#name:hover {
  text-shadow: 0px 1px black;
}
#name-description {
  font-family: initial;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1px;
  color: rgb(255, 238, 128);
  text-shadow: 1px 1px black;
}
#navlinks {
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 15px;
  text-shadow: none;
  color: rgb(255, 250, 196);
}

@media (max-width: 600px) {
  #navbar-items {
    width: 100vw;
  }
  #name-description {
    visibility: hidden;
  }
  #name {
    font-size: 10px;
  }
  #navlinks {
    font-size: 12px;
    margin-right: 5px;
  }
  #name-container {
    width: 30%;
  }
}
