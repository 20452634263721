.project {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 50px;
  margin-bottom: 50px;
}
.gif {
  overflow: hidden;
  object-fit: cover;
  width: 450px;
  height: 300px;
}
#projects {
  height: fit-content;
  padding-top: 5rem;
  /* padding-bottom: 30rem; */
}
#projects-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#project-thumbnails {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
}

.top-left {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  text-shadow: none;
  background-color: rgba(23, 25, 26, 0.857);
  width: 100%;
  height: 98%;
}

.project-text-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.project-name {
  font-weight: 600;
  letter-spacing: 0px;
  font-size: 2rem;
  color: rgb(255, 238, 128);
}
.project-description {
  font-weight: 100;
  font-size: 18px;
  font-family: "Signika Negative";
  text-align: center;
  margin-left: 2rem;
  margin-right: 2rem;
}

@media (max-width: 1220px) {
  .gif {
    width: 350px;
    height: 200px;
  }
}

@media (max-width: 540px) {
  #projects {
    overflow: hidden;
    height: 100vh;
  }
  #project-thumbnails {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .project {
    margin-top: 0px;
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
  }
  .gif {
    max-width: 100vw;
    width: auto;
    height: auto;
    max-height: 300px;
  }
}
