@import url("https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Questrial&display=swap");
body {
  color: rgb(255, 255, 255);
  background-color: rgb(23, 25, 26);
  text-shadow: 2px 4px rgba(0, 0, 0, 0.879);
  font-size: 60px;
  font-family: "JMH Typewriter", Georgia, "Times New Roman", Times, serif;
  font-weight: 600;

  position: relative;
  height: fit-content;
}
body::before {
  content: "";
  width: 100%;
  height: 130vh;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-image: linear-gradient(
      to bottom,
      rgba(188, 114, 114, 0),
      rgb(31, 33, 34)
    ),
    url("https://lirents.net/wp-content/uploads/printjaguar-4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(70%) saturate(125%);
}
header {
  grid-row: 1;
  text-align: center;
  margin-top: 20px;
}
footer {
  display: flex;
  flex-direction: column;
  text-align: end;
  font-family: "Signika Negative";
  font-size: 15px;
  height: 5rem;
  background-color: rgba(189, 113, 113, 0.026);
}
h1 {
  font-weight: 500;
  font-size: 50px;
  letter-spacing: 2px;
}
h2 {
  font-weight: 500;
  font-size: 35px;
  color: azure;
}

.center-card {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.694);
  color: white;
  padding: 100px;
  padding-bottom: 10px;
  overflow-y: scroll;
  backdrop-filter: blur(10px);
}
.links {
  position: relative;
  right: 10px;
  text-decoration: underline;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.under-construction {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 43rem;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  font-size: 20px;
  justify-content: center;
  color: rgb(255, 250, 196);
  background-color: rgba(23, 25, 26, 0.492);
  backdrop-filter: blur(5px);
}
#component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}
hr {
  border-bottom: 1px solid #504c49c8;
  width: 90%;
  box-shadow: 0 20px 20px -20px #333;
}
a:hover {
  cursor: pointer;
  background-color: rgb(232, 217, 53);
  text-shadow: 1px 2px rgba(0, 0, 0, 0.879);
}
a:link {
  text-decoration: none;
  color: white;
}
a:visited {
  color: inherit;
}
a:active {
  color: inherit;
}

a:hover .hover-hidden {
  display: none;
}
.hidden-until-hover {
  display: none;
  margin-right: 70px;
  transition: 2s;
}
a:hover .hidden-until-hover {
  display: inline;
}

@media (max-width: 2000px) {
  body {
    font-size: 30px;
  }
  .center-card {
    width: auto;
    margin: 60px 50px 60px 50px;
  }
  footer {
    justify-content: center;
    margin-right: 0px;
  }
}

@media (max-width: 370px) {
  .center-card {
    width: 90%;
    margin: auto;
  }
}
