#about-container {
  font-weight: 400;
  font-size: 20px;
  text-decoration: none;
  text-align: left;
  width: 100vw;
  padding-top: 2rem;
}
#about {
  display: flex;
  flex-direction: column;
}

#about-body {
  display: flex;
  flex-direction: column;
}
#about-items {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  margin: 30px 100px 0px 100px;
}
#about-paragraph {
  font-family: "Helvetica";
  font-weight: 700;
  font-size: 20px;
  width: 50vw;
}

#profile-pic {
  width: 75vw;
  height: 100%;
  align-self: center;
}

@media (max-width: 1300px) {
  #about {
    font-size: 25px;
    width: 100%;
  }
  #about-items {
    width: 100vw;
    margin: 0;
  }
}

@media (max-width: 540px) {
  #about-container {
    padding-top: 4rem;
  }
  #about-items {
    flex-direction: column;
    width: 100vw;
  }
  #about-paragraph {
    width: 100vw;
    font-size: 15px;
  }
  #profile-pic {
    width: 100vw;
  }
}
