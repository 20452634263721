#contact {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
}
#contact-info {
  display: flex;
  flex-direction: column;
  font-family: "Signika Negative";
  text-shadow: 1px 2px black;
  font-size: 20px;
}
#social-links img {
  filter: invert();
  width: 30px;
  margin-right: 0.5rem;
}
#social-links a:hover {
  background-color: inherit;
  filter: sepia(100%) saturate(1600%) hue-rotate(0deg);
}
#social-links img:hover {
  transform: scale(110%);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@media (max-width: 1000px) {
  #contact {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 540px) {
  #contact-info {
    font-size: 15px;
    margin: 0;
    padding: 0;
  }
}
